.badge-dark {
  border-radius: 1rem;
  background-image: linear-gradient(160deg, #00F5A5 0%, #04Fa46 75%, #78FD15 100%);
  padding: 0.05rem;
}

.badge-dark div {
  font-family: 'fira-sans-m', sans-serif;
  font-size: 0.6rem;
  background-color: #333F50;
  color: #FFF;
  border-radius: 1rem;
  width: 100%;
  height: 100%;
  padding: 0.125rem 0.4rem;
}