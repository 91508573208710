/* Sizing Styles */

/* Height */
.h-10 {
  height: 10%;
}

.h-15 {
  height: 15%;
}

.h-20 {
  height: 20%;
}

.h-85 {
  height: 85%;
}

.h-90 {
  height: 90%;
}

.hmin-0 {
  min-height: 0;
}

.hmin1-0 {
  min-width: 0px;
}

.hmin-90 {
  min-height: 90%;
}

.hmin-100 {
  min-height: 100%;
}


/* Width */
.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}