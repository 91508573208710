/* Margins */

/* Top margins */
.mt-6 {
  margin-top: 4rem;
}

.mt-9 {
  margin-top: 7rem;
}

/* Bottom margins */
.mb-6 {
  margin-bottom: 4rem;
}

.mb-9 {
  margin-bottom: 7rem;
}

.mb-12 {
  margin-bottom: 10rem;
}

.py-7 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}


@media (min-width: 578px) {
  .mx-sm-6 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
}
