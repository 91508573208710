/* Background styles */
.bg-gradient-green {
  background-image: linear-gradient(160deg, #00F5A5 0%, #04Fa46 75%, #78FD15 100%);
}

.bg-gradient-dark {
  background-image: linear-gradient(to top, #1A2028, #333744);
}

.bg-pana-dark {
  background-color: #1A2028;
}

.bg-grey-secondary {
  background-color: #333F50;
}

/* Deprecated */
.bg-pana-sidebar {
  background-image: linear-gradient(to top, #0E0E0E, #1A2028);
}
