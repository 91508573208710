/* Cards */
.pana-card {
  background-color: #1F252C;
  color: #FFF;
  border-radius: 0;
  font-size: 1rem;
  border: 0;
}

.pana-card h1 {
  font-size: 1.5rem;
}

.pana-card p {
  font-size: small;
}

.pana-card p, span {
  color: #CCC;
}