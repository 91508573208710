/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #1F252C; 
}

::-webkit-scrollbar-thumb {
  background: #CCC; 
}

::-webkit-scrollbar-thumb:hover {
  background: #CCC; 
}

/* Scrolling */
.horizontal-scroll {
  overflow-x: auto;
}

.vertical-scroll {
  overflow-y: auto;
}

.disable-scroll {
  overflow-y: hidden !important;
}

.disable-scroll-x {
  overflow-x: hidden !important;
}

.overflow-auto {
  overflow: auto;
}
