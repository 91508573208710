/* Table */
.table {
  border: 0;
}

.table .thead-dark th {
  background-color: #1F252C;
  border: 2px solid #333744;
  color: #999999;
}

.table .thead-dark .wmin-8 {
  min-width: 8rem;
}

.table .thead-dark .wmin-6 {
  min-width: 6rem;
}

.table .tbody-dark .wmax-9{
  max-width: 9rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table .thead-dark .wmax-9 {
  max-width: 9rem;
}

.table .tbody-dark td, .table .tbody-dark th {
  background-color: #363A44;
  border: 2px solid #1F252C;
  color: #CCC;
}


.table .tbody-dark * {
  color: #CCC;
  font-size: 0.8rem;
  font-family: 'fira-sans-l'
}

.table .tbody-dark td {
  border-left-style: none;
  border-right-style: none;
}

.table .tbody-dark tr:last-child td {
  border-bottom-style: none;
}

.table .tbody-dark th {
  border-style: none;
}

.table .thead-dark th {
  border-top-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-right-style: none;
}

.highlight-row td {
  background-color: #454A57 !important;
}

/* Paginator styles */
.page-link {
  color: #999999 !important;
  background-color: #363A44;
  border: 1px solid #363A44;
}

.page-item.disabled .page-link {
  color: #999999;
  pointer-events: none;
  cursor: auto;
  background-color: #363A44;
  border-color: #363A44;
}

.page-item .page-link:hover,
.page-item.active .page-link {
  color: #999999;
  background-color: #1F252C !important;
  border-color: #1F252C !important;
}

a:not([href]):not([tabindex]):focus {
  color: #999999;
  text-decoration: none;
}

.pagination * {
  color: #999999;
}