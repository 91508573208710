/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/dist/css/bootstrap.css";
@import "~font-awesome/css/font-awesome.css";
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@font-face {
  font-family: fira-sans-xl;
  src: url(../assets/fonts/FiraSans-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: fira-sans-l;
  src: url(../assets/fonts/FiraSans-Light.ttf) format("truetype");
}

@font-face {
  font-family: fira-sans;
  src: url(../assets/fonts/FiraSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: fira-sans-m;
  src: url(../assets/fonts/FiraSans-Medium.ttf) format("truetype");
}

@font-face {
  font-family: fira-sans-b;
  src: url(../assets/fonts/FiraSans-Bold.ttf) format("truetype");
}

html, body {
  height: 100vh;
}

/* Font Styles */

* {
  font-family: 'fira-sans', sans-serif;
  color: #FFF;
}

/*
h2 {
  font-family: 'fira-sans-m' !important;
} */

h3 {
  font-family: 'fira-sans-xl', sans-serif !important;
  color: #FFF;
}

.fa {
  font-family: FontAwesome !important;
}

fa * {
  color: #999999;
}

/* Helpers */

.wrapper {
  word-wrap: break-word !important;
  white-space: normal !important;
}

.clickable {
  cursor: pointer;
}

/*
 * Margin value is calculated based on total height of section's title container
 * All content vertically centered under section's title container should be wrapped
 * in a container with this class
 */

.main-section {
  margin-top: -5.6rem;
}

.default-cursor {
  cursor: default !important;
}

.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}

app-generic-confirmation-modal, app-mass-load-modal, app-register-modal, app-create-department {
  max-width: 15rem;
}

.spinner-three-bounce {
  display: none;
}

.spinner-pulse {
  width: 40px;
  height: 40px;
  background-color: #04Fa46;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 2000;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

.si-content div {
  color: #CCC;
}

.si-shadow-frame {
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.75) !important;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.75) !important;
}

@-moz-document url-prefix() {
  .align-items-end {
    padding-top: 55vh !important;
  }
  .vertical-scroll {
    position: absolute;
    width: 83vw;
  }

  @media screen and (max-width: 600px) {
    div.vertical-scroll {
      width: 100vw !important;
    }
  }
}
.mat-progress-bar-fill::after {
  background-color: #04Fa46;
}

.modal-content {
  min-height: 500px !important;
}

.agm-map-content {
  position: absolute;
  background: url('/assets/panapinsmall.png') no-repeat;
  top: 50%;
  left: 50%;
  height: 35px;
  width: 25px;
  margin-left: -12px;
  margin-top: -35px;
  cursor: pointer;
  display: block !important;
  z-index: 400;
}
