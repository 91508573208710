/* Button Styles */
button,
button:active {
  outline: none !important;
  border: none !important;
  /* -webkit-box-shadow: none !important;
  box-shadow: none !important; */
}

button:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none!important;
  -webkit-box-shadow: none!important;
}

.btn-pana-gradient {
  font-family: 'fira-sans-m', sans-serif !important;
  font-size: 0.8rem;
  color: #333F50;
  border-radius: 2rem;
  background-image: linear-gradient(160deg, #00F5A5 0%, #04Fa46 75%, #78FD15 100%);
  padding: 0.475rem 0.75rem;
  max-width: 15rem;
  margin-top: 1.5rem;
  -webkit-box-shadow: 0px 1px 13px 0px rgba(0,0,0,0.45);
  -moz-box-shadow: 0px 1px 13px 0px rgba(0,0,0,0.45);
  box-shadow: 0px 1px 13px 0px rgba(0,0,0,0.45);
}

.btn-pana-gradient-dark {
  border-radius: 2rem;
  background-image: linear-gradient(160deg, #00F5A5 0%, #04Fa46 75%, #78FD15 100%);
  padding: 0.05rem;
  max-width: 15rem;
  margin-top: 1.5rem;
  -webkit-box-shadow: 0px 2px 25px 0px rgba(0,0,0,0.10);
  -moz-box-shadow: 0px 2px 25px 0px rgba(0,0,0,0.10);
  box-shadow: 0px 2px 25px 0px rgba(0,0,0,0.10);
}

.btn-pana-gradient-dark div {
  font-family: 'fira-sans-m', sans-serif;
  font-size: 0.8rem;
  background-color: #333F50;
  color: #FFF;
  border-radius: 2rem;
  width: 100%;
  height: 100%;
  padding: 0.425rem 0.7rem;
}

.btn-pana-link {
  font-weight: 400;
  color: #fff;
  background-color: transparent;
  max-width: 15rem;
  margin-top: 1.5rem;
}

.btn-pana-link:hover {
  color: #04Fa46;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

/* DEPRECATED */

.btn-wrap {
  max-width: 80%;
  word-wrap: break-word !important;
  white-space: normal !important;
  font-style: normal;
  font-size: 9px ! important;
}

.btn-pana {
  color: #343a40;
  background-color: #94FF01;
  border-color: #94FF01;
  font-weight: bold
}

.btn-xs,
.btn-group-xs>.btn {
  padding: 0.05rem;
  /* padding: 0.25rem 0.5rem; */
  /* padding: 0.475rem 0.75rem; */
  font-size: 0.6rem;
  line-height: 1.5;
  max-width: 10rem;
}

.btn-xs div {
  padding: 0.2rem 0.45rem;
  font-size: 0.6rem;
  line-height: 1.5;
}

.btn-wider {
  width: 85%;
}

.btn-wide {
  width: 65%;
}

.btn-block {
  width: 100%;
}

.modal-content .btn-pana-gradient,
.modal-content .btn-pana-gradient-dark {
  width: 10rem !important;
}
