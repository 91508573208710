.dropbtn:active,
.dropbtn:focus,
.dropbtn {
  background-color: transparent !important;
  border: none;
  border-radius: 0;
  padding-top: 0;
  padding-bottom: .4375em;
  color: #BABEC7;
  outline: none !important;
  box-shadow: none;
  font-family: 'fira-sans-l', sans-serif !important;
}

.dropbtn option {
  padding: 2rem;
}

.dropbtn::-webkit-input-placeholder {
  color: #81858D;
}

.dropbtn:-moz-placeholder {
  color: #81858D;
}

.dropbtn::-moz-placeholder {
  color: #81858D;
}

.dropbtn:-ms-input-placeholder {
  color: #81858D;
}

.list-group {
  background-color: #1F252C;
  overflow-y: auto;
  padding-bottom: 1rem;
}

.list-group .list-group-item {
  background-color: transparent;
  font-size: small;
  padding: 0.5rem 1rem;
  color: #BABEC7;
  width: 100%;
  cursor: pointer;
}

.list-group .list-group-item:hover {
  background-color: #363A44;
}

.list-group .list-group-item .country-name {
  background-color: transparent;
  font-size: small;
  padding: 0.5rem 1rem;
  color: #BABEC7;
  width: 100%;
  cursor: pointer;
}

.phone-prefix {
  font-size: 1.7rem;
  /* padding: .4375em 0; */
  /* border-top: .84375em solid transparent; */
  border-bottom: 1.25em;
  font-family: 'fira-sans-l', sans-serif !important;
  width: 7rem;
}

.phone-prefix1 {
  font-size: 1.7rem;
  /* padding: .4375em 0; */
  /* border-top: .84375em solid transparent; */
  border-bottom: 1.25em;
  font-family: 'fira-sans-l', sans-serif !important;
  width: 10rem;
}



.phone-prefix .input-group {
  padding: .08em 0;
  border-top: .84375em solid transparent;
}

.phone-prefix .form-control {
  font-size: 1.5rem;
  caret-color: #BABEC7;
  color: #BABEC7 !important;
  font-family: 'fira-sans-l', sans-serif !important;
  border: none;
  padding-left: 0px;
  padding-top: 0;
  padding-bottom: .08em;
}

.phone-prefix.small {
  box-sizing: border-box;
  display: block;
  line-height: 1.125;
  overflow-wrap: break-word;
  padding-bottom: 1.25em;
  position: relative;
  text-align: left;
  text-size-adjust: 100%;
  font-size: 1rem;
  font-family: 'fira-sans-l', sans-serif !important;
  width: 3.5rem;
}

.phone-prefix.small .input-group {
  align-items: baseline;
  box-sizing: border-box;
  display: inline-flex;
  line-height: 1.125;
  overflow-wrap: break-word;
  text-align: left;
  text-size-adjust: 100%;
  padding: .25em 0;
  border-top: 0;
}

.phone-prefix.small .dropbtn {
  padding-left: 0;
  padding-right: 0.4rem;
  padding-bottom: 0.1rem;
}

.phone-prefix.small .form-control {
  display: block;
  box-sizing: border-box;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  line-height: 1.125;
  font-size: 1rem;
  height: auto;
  caret-color: #BABEC7;
  color: #BABEC7 !important;
  font-family: 'fira-sans-l', sans-serif !important;
  border: none;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: .4375em;

}
