/* Dropdowns */
.dropdown-container {
  background-color: #1F252C;
  overflow-y: auto;
  border-radius: 0;
  /* padding-bottom: 1rem; */
  position: absolute;
  width: 100%;
  -webkit-box-shadow: 0px 13px 42px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 13px 42px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 13px 42px -2px rgba(0,0,0,0.75);
}

.dropdown-container > div {
  max-height: 45vh;
}

.dropdown-menu {
  padding: 0;
  border: 0;
  -webkit-box-shadow: 0px 0px 31px 2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 31px 2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 31px 2px rgba(0,0,0,0.75);
}

.dropdown-element {
  background-color: transparent;
  font-size: small;
  padding: 0.5rem 1.5rem;
  color: #CBCBCB;
  width: 100%;
  cursor: pointer;
}

.dropdown-no-match {
  background-color: transparent;
  font-size: small;
  padding: 0.5rem 1.5rem;
  color: #CBCBCB;
  width: 100%;
}

.dropdown-element *,
.dropdown-no-match * {
  color: #CBCBCB;
}

.dropdown-element span {
  font-family: 'fira-sans-m', sans-serif;
}

.dropdown-element:hover {
  background-color: #373745;
}

.dropdown-input-expanded {
  border-radius: 0 !important;
  border-bottom: 2px solid #373745 !important;
}

.dropdown-element .badge {
  background-image: linear-gradient(160deg, #00F5A5 0%, #04Fa46 75%, #78FD15 100%);
  border-radius: 1rem;
  color: #333F50;
}

.pac-logo:after {
  display: none;
}

.pac-item {
  background-color: transparent;
  font-size: small;
  padding: 0.5rem 1.5rem;
  color: #CBCBCB;
  width: 100%;
  cursor: pointer;
  border: none;
}

.pac-item span {
  font-size: small;
  color: #CBCBCB;
}

.pac-item:hover {
  background-color: #373745;
}

.pac-item .pac-icon.pac-icon-marker {
  background-image: url("/assets/pin-gray.png");
  background-size: 23px 23px;
  background-position-x: calc(100% - 1.35rem);
  background-position-y: 130%;

}

.pac-item.pac-item-selected .pac-icon.pac-icon-marker {
  background-image: url("/assets/pin-green.png");
  
  background-size: 23px 23px;
  background-position-x: calc(100% - 1.35rem);
  background-position-y: 130%;

}

.pac-item .pac-item-query .pac-matched {
  font-size: small;
  color: #CBCBCB;
}

.pac-container {
  background-color: #1F252C;
  overflow-y: auto;
  border-radius: 0;
  border: none;
  /* padding-bottom: 1rem; */
  position: absolute;
  width: 100%;
  -webkit-box-shadow: 0px 13px 42px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 13px 42px -2px rgba(0,0,0,0.75);
  box-shadow: 0px 13px 42px -2px rgba(0,0,0,0.75);
}
