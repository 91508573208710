/* Inputs */

/* Styles for third party components using default form-control bootstrap class */
.form-control:active,
.form-control:focus,
.form-control {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #999;
  border-radius: 0;
  color: #FFF;
  outline: none !important;
  box-shadow: none;
}

.form-control option {
  padding: 2rem;
}

.form-control::-webkit-input-placeholder {
  color: #999;
}

.form-control:-moz-placeholder {
  color: #999;
}

.form-control::-moz-placeholder {
  color: #999;
}

.form-control:-ms-input-placeholder {
  color: #999;
}


/* Styles for pill like inputs */
.input-pana-darker:active,
.input-pana-darker:focus,
.input-pana-darker {
  background-color: #1F252C;
  border: 0;
  border-radius: 2rem;
  color: #FFF;
  outline: none !important;
  box-shadow: none;
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 1rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5;
}

.input-pana-darker option {
  padding: 2rem;
}

.input-pana-darker::-webkit-input-placeholder {
  color: #999;
}

.input-pana-darker:-moz-placeholder {
  color: #999;
}

.input-pana-darker::-moz-placeholder {
  color: #999;
}

.input-pana-darker:-ms-input-placeholder {
  color: #999;
}

/* Styles for transparent inputs */

/* Input element style */
.mat-form-field {
  max-width: 20rem;
  font-size: 1.5rem;
}

.mat-form-field.small {
  max-width: 10rem;
  font-size: 1rem;
}

.modal-body .mat-form-field.small {
  max-width: none;
  font-size: 1rem;
}

.mat-form-field.small .mat-form-field-infix {
  border: 0;
}

.mat-form-field .mat-error {
  font-size: 0.8rem;
}

.mat-form-field-appearance-legacy .mat-form-field-label,
.mat-input-element {
  font-family: 'fira-sans-l', sans-serif !important;
}

.mat-input-element {
  caret-color: #FFF;
  color: #FFF !important;
}

/* Label unfocused style */
.mat-form-field-appearance-legacy .mat-form-field-label {
  color: #999;
}

/* Label focused style */
.mat-form-field.mat-focused .mat-form-field-label {
  color: #FFF;
}

/* Border bottom input unfocused style */
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #999;
}

/* Border bottom input focused style */
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #FFF;
}

/* Input element invalid style */
.mat-form-field-invalid .mat-input-element {
  caret-color: #FFF;
  color: #FFF !important;
}

/* Invalid input label unfocused style */
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #999;
}

/* Invalid input label focused style */
.mat-form-field.mat-focused.mat-form-field-invalid .mat-form-field-label {
  color: #FFF;
}

/* Invalid input border bottom unfocused style */
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
  background-color: #999;
}

/* Invalid input border bottom focused style */
.mat-form-field.mat-focused.mat-form-field-invalid .mat-form-field-ripple {
  background-color: #FFF;
}

/* Errors color */
.mat-error, .mat-error div {
  color: #04Fa46 !important;
}


/* Mat inputs centered styles */
.mat-form-field.centered .mat-form-field-infix {
  text-align: center;
}

.mat-form-field.centered .mat-error {
  text-align: center;
}

.mat-select-content {
  background-color: #1F252C;
}

.mat-select-arrow {
  color: #999;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #FFF;
}

.mat-select {
  font-family: 'fira-sans-l', sans-serif !important;
}

.mat-select-value-text span {
  font-family: 'fira-sans-l', sans-serif !important;
  color: #FFF;
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #999;
}

.mat-form-field.id-type-select {
  width: 2.5rem !important;
}

.mat-form-field.small.id-type-select {
  width: 2rem !important;
}

.mat-form-field.small.document-field {
  width: calc(100% - 2rem);
}

.mat-form-field.small.phone-field {
  width: calc(100% - 7rem);
}

#id-errormessage .mat-form-field-subscript-wrapper {
  margin-left: -3rem;
}


#id-errormessage1 .mat-form-field-subscript-wrapper {
  margin-left: -2.5rem;
  width: unset;
}

#phone-errormessage .mat-form-field-subscript-wrapper {
  margin-left: -5.5rem;
}

#phone-errormessage1 .mat-form-field-subscript-wrapper {
  margin-left: -3.5rem;
  width: unset;
}

.input-pana-busqueda {
  background-image: url("/assets/icono-search.png");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position-x: calc(100% - 1.25em);
  background-position-y: 50%;
  padding-right: 3em !important;
  padding-left: 1.25rem !important;
}

