/* Text transformers */
.highlight {
  /* background-color: #1F252C; */
  font-weight: bold;
}

.text-green {
  color: #04Fa46 !important;
}

.text-gray {
  color: #999999 !important;
}

.text-light-gray {
  color: #cccccc !important;
}

.font-light {
  font-family: 'fira-sans-l', sans-serif;
}

.font-medium {
  font-family: 'fira-sans-m', sans-serif;
}

.text-pink {
  color: #ff2193 !important;
}