/* Modals */

/* @media (min-width: 768px) {
  .modal-dialog {
    width: 60rem;
    max-width: none;
  }
} */

/* @media (min-width: 992px) {
  .modal-dialog {
    width: 40vw;
    max-width: none;
  }
} */

.modal-content {
  -webkit-box-shadow: 0px 0px 31px 2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 31px 2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 31px 2px rgba(0,0,0,0.75);
}

.modal-content {
  background-color: #1F252C;
  color: #FFF;
  border-radius: 0;
  min-height: 60vh;
}

.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem;
}

.modal-body .content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-body p {
  color: #cccccc;
}